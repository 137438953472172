import { datadogRum } from '@datadog/browser-rum';
import { Context } from '@datadog/browser-core';
import { getEnvVariable } from './EnvironmentService';
import {
	DATADOG_RUM_ENV,
} from '../constants/environmentVariables.config';
import packageInfo from '../../package.json';

export const DATADOG_APP_ID = '1969af8b-2302-46f9-8c25-d448bdca9111';
export const DATADOG_CLIENT_TOKEN = 'pub132fdd63095ba3d5b66ec9c40d739cd2';
export const DATADOG_SITE = 'datadoghq.com';

const ddEnv = getEnvVariable(DATADOG_RUM_ENV);
const ddServiceName = `irc-workflow-${ddEnv}`;
const currentAppVersion: string | undefined = ddEnv === 'local' ? 'local' : packageInfo.version;

export const addCurrentUserToEUM = (userPrincipal: string) => {
	try {
		if (userPrincipal) {
			datadogRum.setUser({ id: userPrincipal });
		} else {
			console.error("Current user principal is not defined, unable to add user info to RUM session");
		}
	} catch (err) {
		console.error('Error thrown getting user principal', err);
	}
};

export const initializeEUM = () => {
	try {
		const eumConfig = {
			applicationId: DATADOG_APP_ID,
			clientToken: DATADOG_CLIENT_TOKEN,
			site: DATADOG_SITE,
			service: ddServiceName,
			env: ddEnv,
			// Specify a version number to identify the deployed version of your application in Datadog
			version: currentAppVersion,
			// !!!!!!!!!!!!!!! DO NOT UPDATE THIS !!!!!!!!!!!!!!!
			// We need this to be false otherwise we could be tracking interactions
			// containing CONFIDENTIAL information
			trackInteractions: false,
			// !!!!!!!!!!!!!!! DO NOT UPDATE THIS !!!!!!!!!!!!!!!!
			allowedTracingUrls: [window.location.origin],
			// teams doesn't have local storage so we need to track sessions using cookies
			useCrossSiteSessionCookie: true,
			usePartitionedCrossSiteSessionCookie: true,
			useSecureSessionCookie: true,
			trackSessionAcrossSubdomains: false
		};

		datadogRum.init(eumConfig);
	} catch (err) {
		console.warn(`Failed to initialize EUM with error ${err}`);
	}
};

export const reportToEUM = (message: string, tags: Context = {}) => {
	datadogRum.addAction(message, tags);
};
