import React from 'react';
import {createRoot} from 'react-dom/client';
import './main.scss';
import {App} from "./App";
import {initializeEUM} from "./services/EUMService";

const root = createRoot(document.getElementById('root')!);

initializeEUM();

root.render(
    <React.StrictMode>
            <App/>
    </React.StrictMode>
);