import {
	toastService,
	ToastConfig,
	ToastDismissReason,
	ToastRef,
	ToastPlacement,
} from '@gs-ux-uitoolkit-react/toast';

export type Notifier = (options: ToastConfig) => ToastRef;

export const showToast: Notifier = (options: ToastConfig) => {
	let dismissible = true;
	if (options.dismissible === false) {
		dismissible = false;
	}

	let toastPlacement: ToastPlacement = 'top-right';
	if (options.placement) {
		toastPlacement = options.placement;
	}

	return toastService.show({
		...options,
		onDismiss: (reason: ToastDismissReason) => {
			options.onDismiss && options.onDismiss(reason);
		},
		onShow: () => {
			options.onShow && options.onShow();
		},
		onHide: () => {
			options.onHide && options.onHide();
		},
		placement: toastPlacement,
		dismissible,
	});
};
