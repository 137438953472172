import React from 'react';
import {Link} from 'react-router-dom';
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import {clearCacheOfQueue, currentQueueVar} from "../../../constants/cache";
import {useApolloClient} from "@apollo/client";
import {Col, Row} from "@gs-ux-uitoolkit-react/layout";
import {RequestDetailsBadge} from "../../Badges/RequestDetailBadge";
import {getFirstNameInitialAndLastName} from "../../../services/utils/DisplayNameService";

interface RequestTitleProps {
    data: any;
}

export const RequestTitle = ({data} : RequestTitleProps) => {
    const client = useApolloClient();

    const onClick = () => {
        clearCacheOfQueue(client, currentQueueVar());
    }

    const arrowColor = {color: 'var(--gs-uitk-color-scheme-primary)'}

    return (
        <div className="flex pa1 justify-between nowrap orange">
            <div className="flex flex-fixed black">
                <Row>
                    <Link to="/" className="ml1 no-underline black" onClick={onClick}>
                        <Icon name="arrow-back-ios" type="filled" size="md" style={arrowColor}/>
                    </Link>
                    <Col style={{textAlign: 'right'}}>
                        <RequestDetailsBadge
                            approvedBy={data?.approvedBy}
                            claimedBy={getFirstNameInitialAndLastName(data?.claimedBy, data?.peopleMetadata)}
                            status={data?.ircRequestStatus}/>
                    </Col>
                </Row>
            </div>
        </div>
    );
};