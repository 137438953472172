import React, {useEffect, useState} from 'react';
import {useStyleSheet} from '@gs-ux-uitoolkit-react/style';
import {Step, Steps} from "@gs-ux-uitoolkit-react/steps";
import {wizardStyleSheet} from "../../constants/StyleSheets";
import {ApprovalWizardFooter} from "./ApprovalWizardFooter";
import {ApprovalsContent} from "./ApproveContent";
import {SummaryReview} from "./Summary/SummaryReview";
import {useMutation, useQuery} from "@apollo/client";
import {getApproveButtonMessage, hasCurrentUserApproved} from "../../services/utils/ApproveService";
import {GET_REQUEST_DETAILS, UPDATE_REQUEST_APPROVERS} from "../../constants/GraphqlCallDefinitions";
import {ApproveModalStep} from "../../constants/ApproveModalSteps";
import {ApolloQueryHandler} from "../ApolloQueryHandler";
import {ApprovalComment} from './ApprovalComment/ApprovalComment';
import {reportToEUM} from "../../services/EUMService";
import {NOT_RATED_LEGAL_CHANGE} from "../../constants/ChangeTypes";

interface ApproveWizardProps {
    closeModal: () => void;
    isDisabled: boolean
}

interface ApprovalComment {
    comment: string | undefined,
    author: string,
    lastUpdated?: string
}

export const ApproveWizard = ({
                                  closeModal,
                                  isDisabled
                              }: ApproveWizardProps) => {
    const {data} = useQuery(GET_REQUEST_DETAILS);
    const isNotRatedLegalChange = () => data?.getIRCRequest?.ircSubmission?.ircChangeTypes?.includes(NOT_RATED_LEGAL_CHANGE);
    const [currentApprovers, setCurrentApprovers] = useState<string[]>([]);
    const [currentApprovalComment, setCurrentApprovalComment] = useState<ApprovalComment | undefined>(data?.getIRCRequest.approvalComment);
    const [previousApprovalComment, setPreviousApprovalComment] = useState<ApprovalComment>();
    const [approvalMessage, setApprovalMessage] = useState<string>('Approve');
    const [currentAuthor, setCurrentAuthor] = useState<string>(data?.getIRCRequest?.approvalComment?.author);
    const [currentStep, setCurrentStep] = useState(0);
    const [approversWarningMessage, setApproversWarningMessage] = useState<string | undefined>(undefined);
    const [notRatedLegalChange, setNotRatedLegalChange] = useState<boolean>(isNotRatedLegalChange);

    const [updateApprovers, {
        loading,
        error
    }] = useMutation(UPDATE_REQUEST_APPROVERS, {variables: { updatedAttributes: { approvedBy: currentApprovers }}});
    const cssClasses = useStyleSheet(wizardStyleSheet, null);

    useEffect(() => {
        setPreviousApprovalComment(data?.getIRCRequest?.approvalComment)
        setCurrentApprovers(data?.getIRCRequest?.approvedBy ? data?.getIRCRequest?.approvedBy : []);
    }, [data?.currentUser]);

    useEffect(() => {
        setCurrentApprovalComment(data?.getIRCRequest?.approvalComment);
    }, [data?.getIRCRequest?.approvalComment]);

    useEffect(() => {
        setApprovalMessage(getApproveButtonMessage(data));
    }, [data?.getIRCRequest?.ircRequestStatus]);

    useEffect(() => {
        setNotRatedLegalChange(isNotRatedLegalChange);
    }, [data?.getIRCRequest.ircSubmission.ircChangeTypes])

    /**
     * Moves the wizard back 1 step
     */
    const onBack = () => {
        setCurrentStep(currentStep - 1);
    }

    /**
     * Moves the wizard forward 1 step
     */
    const onNext = () => {
        if (currentStep == ApproveModalStep.ApproverStep.valueOf() && !hasCurrentUserApproved(data, currentApprovers) && approversWarningMessage == undefined) {
            setApproversWarningMessage('You have not approved this request on-behalf of yourself. If you wish to proceed anyways please press Next again.');
            return;
        }
        setCurrentStep(currentStep + 1);
        setApproversWarningMessage(undefined)
    }

    const updateVisibleComment = async (newComment: string) => {
        setCurrentAuthor(data?.currentUser);
        if (newComment != previousApprovalComment?.comment) {
            const finalComment: ApprovalComment = {comment: newComment, author: currentAuthor};
            setCurrentApprovalComment(finalComment);
        } else {
            setCurrentApprovalComment(previousApprovalComment)
        }
    }

    const onApprove = async () => {
        if (data?.currentRequestId && previousApprovalComment?.comment != currentApprovalComment?.comment) {
            reportToEUM(`${currentAuthor} approved and updated approval comment for request ${data?.currentRequestId}"`);
            await updateApprovers({
                variables: {
                    updatedAttributes: {
                        approvedBy: currentApprovers,
                        approvalComment: currentApprovalComment
                    }
                }
            })
        } else {
            reportToEUM(`Approved request ${data?.currentRequestId}`);
            updateApprovers();
        }
        setCurrentStep(currentStep + 1);
    }

    return (
        <div className={cssClasses.container}>
            <div className={cssClasses.steps}>
                <Steps currentStep={currentStep}>
                    <Step label="Review Summary"/>
                    <Step label="Approval"/>
                    <Step label="Approval Notes"/>
                </Steps>
            </div>
            {currentStep == ApproveModalStep.SummaryReviewStep.valueOf() &&
				<SummaryReview/>
            }
            {currentStep == ApproveModalStep.ApproverStep.valueOf() &&
				<ApprovalsContent
					isDisabled={isDisabled}
					currentApprovers={currentApprovers}
					setCurrentApprovers={setCurrentApprovers}
					approversWarningMessage={approversWarningMessage}
				/>
            }
            {currentStep == ApproveModalStep.ApproverCommentStep.valueOf() &&
				<ApprovalComment
					updateVisibleComment={updateVisibleComment} isDisabled={isDisabled}
				/>
            }
            {currentStep == ApproveModalStep.FinishedStep.valueOf() &&
				<ApolloQueryHandler
					errors={error}
					loading={loading}
					alertMessage={"Failed to update approvers. If the error persists, please reach out to the Support team for assistance."}
					ChildComponent={<div>Success!</div>}
				/>
            }

            <ApprovalWizardFooter
                step={currentStep}
                onNext={onNext}
                onBack={onBack}
                error={error}
                onApprove={onApprove}
                onClose={closeModal}
                approveButtonMessage={approvalMessage}
                isDisabled={isDisabled}
                notRatedLegalChange={notRatedLegalChange}
            />
        </div>
    );
};
