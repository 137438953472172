import * as variables from './environmentVariables.config';

export default {
    [variables.IRC_WORKFLOW_SERVICE_URL]: window.location.origin,
    [variables.GONE_FISHING]: false,
    [variables.GONE_FISHING_START_DATE]: new Date("2024-06-01T04:00:00Z"),
    [variables.GONE_FISHING_END_DATE]: new Date("2024-06-01T10:00:00Z"),
    [variables.DATADOG_RUM_ENV]: 'prod',
    [variables.USE_ATTACHMENT_DEEP_LINK]: true
};
