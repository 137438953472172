import React, {useEffect} from 'react';
import {app} from '@microsoft/teams-js';
import {currentRequestIdVar, currentUserVar} from "./constants/cache";
import {LoadData} from "./components/LoadData";
import {Route, Routes, useNavigate} from "react-router-dom";
import {GET_REQUEST_DETAILS} from "./constants/GraphqlCallDefinitions";
import {Request} from "./components/Request/Request";
import {Queues} from "./components/Grid/Queues";
import {getCurrentUserFromMicrosoftContext} from "./services/utils/TeamsService";
import {addCurrentUserToEUM} from "./services/EUMService";

export const Concord = () => {
    const navigate = useNavigate();

    useEffect(() => {
        app.getContext().then(context => {
            const userPrincipal = getCurrentUserFromMicrosoftContext(context);
            currentUserVar(userPrincipal);

            addCurrentUserToEUM(userPrincipal);
            if (context.page?.subPageId?.length) {
                currentRequestIdVar(context.page?.subPageId)
                const path = "/request/" + context?.page.subPageId
                navigate(path, {replace: true});
            }
        });
    }, [])

    return (
        <Routes>
            <Route path="/" element={<Queues/>}/>
            <Route path="/request/:requestId"
                   element={<LoadData query={GET_REQUEST_DETAILS} ChildComponent={<Request/>}/>}/>
        </Routes>
    );
};
