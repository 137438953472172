import {Row} from "@gs-ux-uitoolkit-react/layout";
import {getFormattedDateForDashboard, getTimeElapsedSince} from "../../../services/utils/DateService";
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import {ListGroupItemText} from "@gs-ux-uitoolkit-react/list-group";

interface targetPublicationDateProps {
    approvalPreMarketOpen: boolean,
    estimatedPublicationDate: string,
    lastUpdated: string
}

export const TargetPublicationDateColumn = ({
                                                approvalPreMarketOpen,
                                                estimatedPublicationDate,
                                                lastUpdated } : targetPublicationDateProps) => {

    return (
        <>
            <Row>
                {
                    getFormattedDateForDashboard(estimatedPublicationDate)
                }
                {
                    approvalPreMarketOpen &&
                    (
                        <>
                            &nbsp;
                            <Icon data-testid="preMarketOpenWarning" name="exclamation" type="filled"
                                  style={{color: 'var(--gs-uitk-color-core-red-bold)'}}/>
                        </>
                    )
                }
            </Row>
            <Row>
                <ListGroupItemText style={{fontStyle: "italic"}}>
                    Last updated {getTimeElapsedSince(lastUpdated)}
                </ListGroupItemText>
            </Row>
        </>
    )
}
