import React from "react";
import {getAdditionalContextForOtherChange} from "../../../services/utils/AdditionalContextFormatter";
import {AccordionPanel} from "@gs-ux-uitoolkit-react/accordion";
import {ScrollableContext} from "../AdditionalContext/ScrollableContext";

interface NonQuantumChangesPanelProps {
    additionalContextsResponse: any;
    requestDetailsResponse: any;
    onCollapse: any;
    onExpand: any;
    defaultExpanded: boolean;
}

export const NonQuantumChangesPanel = ({
                                           additionalContextsResponse,
                                           requestDetailsResponse,
                                           onCollapse,
                                           onExpand,
                                           defaultExpanded
                                       }: NonQuantumChangesPanelProps) => {
    const OTHER_CHANGES = 'Other / Non-Quantum Data Changes';
    const additionalContexts: Array<any> = getAdditionalContextForOtherChange(additionalContextsResponse?.additionalContexts);

    if (additionalContexts?.length == 0) {
        return null;
    }

    return (
        <AccordionPanel
            defaultExpanded={defaultExpanded}
            header={OTHER_CHANGES}
            onCollapse={() => onCollapse(OTHER_CHANGES)}
            onExpand={() => onExpand(OTHER_CHANGES)}
        >
            <ScrollableContext additionalContexts={additionalContexts} requestDetailsResponse={requestDetailsResponse} showKey={false}/>
        </AccordionPanel>
    );
};
