import React from 'react';
import {Alert} from '@gs-ux-uitoolkit-react/alert';

interface AlertNotification {
    alertMessage?: string,
}

const ErrorAlertNotification = ({alertMessage}: AlertNotification) => {
    return (
        <Alert
            status="error"
            dismissible
        >
            {alertMessage}
        </Alert>
    );
};

export default ErrorAlertNotification;
