import isNil from 'lodash/isNil';
import { BadgeStatus} from '@gs-ux-uitoolkit-react/badge';
import {ChangeTypes, ChangeTypesFormattingMap} from "../../constants/ChangeTypes";
import {RegionFormattingMap, Regions} from "../../constants/Regions";

export const securitiesFormatter = (response: any) => {
    const metadata = response?.securityMetadata?.securityMetadata;
    const tickers = response?.ircSubmission?.tickers;
    return securityMetaDataFormatterWithTickers(metadata, tickers);
}

export const securityMetaDataFormatterWithTickers = (metadata: {displayName: string, ticker: string}[], tickers: string[]) => {
    const formattedMetadata = securityMetaDataFormatter(metadata);
    const additionalTickers = getMissingTickers(metadata, tickers);
    const formattedAdditionalTickers = stringArrayFormatter(additionalTickers);
    if(!formattedMetadata?.length) {
        return formattedAdditionalTickers;
    }
    return additionalTickers?.length ? `${formattedMetadata}, ${formattedAdditionalTickers}` : formattedMetadata;
}

export const stringArrayFormatter = (strings: string[]) => {
    return strings?.join(', ');
}

export const getMissingTickers = (metadata: {displayName: string, ticker: string}[], tickers: string[]) => {
    return tickers?.filter(ticker => !getListOFTickers(metadata)?.includes(ticker));
}

export const getListOFTickers = (metadata: {displayName: string, ticker: string}[]) => {
    return metadata?.map(meta => meta.ticker);
}

export const securityMetaDataFormatter = (metadata: {displayName: string, ticker: string}[]) => {
    return metadata?.map(meta => `${meta.displayName} (${meta.ticker})`).join(', ');
}

export const booleanFormatter = (bool: boolean) => {
    return bool ? 'Yes' : 'No';
}

export const noFormatting = (value: any) => value;

export const getFormattedFieldValueFromResponse = (response: any, valueGetter: (response: any) => any, formatter: (value: any) => string) => {
    const retrievedValue = valueGetter(response);
    const formattedValue = !isNil(retrievedValue) ? formatter(retrievedValue) : '';
    return formattedValue;
}

// TODO: we set a default max approvers of 2 because today it's either fully approved or it has 1/2 approvers (these are the only 2 cases)
export const getStatusBadgeFormattingDetails = (status: string, claimedBy: string, approvers: string[] | undefined, minRequiredApprovers: number = 2) => {
    let badgeDetails: {statusBadgeMessage: string, requestBadgeMessage: string, status: BadgeStatus};

    switch (status) {
        case 'CLAIMED':
            badgeDetails =  getBadgeDetailsForClaimed(approvers, claimedBy, minRequiredApprovers)
            break;
        case 'APPROVED':
            badgeDetails =  {statusBadgeMessage: 'Approved', requestBadgeMessage: 'Approved', status: 'success'};
            break;
        case 'PUBLISHED':
            badgeDetails = {statusBadgeMessage: 'Published', requestBadgeMessage: 'Published', status: 'success'};
            break;
        case 'EXPIRED':
            badgeDetails = {statusBadgeMessage: 'Expired', requestBadgeMessage: 'Expired', status: 'error'};
            break;
        case 'COMPLETED':
            badgeDetails = {statusBadgeMessage: 'Completed', requestBadgeMessage: 'Completed', status: 'success'};
            break;
        case 'ARCHIVED':
            badgeDetails = {statusBadgeMessage: 'Archived', requestBadgeMessage: 'Archived', status: 'error'};
            break;
        case 'OPEN':
        default:
            badgeDetails = getBadgeDetailsForOpen(approvers, minRequiredApprovers);
    }

    return badgeDetails;
};

export const changeTypesFormatters = (changeTypes: ChangeTypes[]) => {
    return changeTypes?.map(type => ChangeTypesFormattingMap[type]).join(', ')
}

export const tickerFormatter = (tickers: string[]) => {
    return tickers?.length > 2 ? `${stringArrayFormatter(tickers.slice(0,2))} +${tickers.length - 2}` : stringArrayFormatter(tickers)
}

export const getRegionDisplayName = (region: string): string => {
    return RegionFormattingMap[region] || region;
}

export function mergeRatingAndNotRatedLegalChangeLabels(ircChangeTypes: any) {
    return ircChangeTypes?.includes("NOT_RATED_LEGAL_CHANGE")
        ? ircChangeTypes.filter((type: any) => type !== "RATING")
        : ircChangeTypes;
}

const getBadgeDetailsForClaimed = (approvers: string[] | undefined, claimedBy: string, minRequiredApprovers: number) : {statusBadgeMessage: string, requestBadgeMessage: string, status: BadgeStatus} => {
    if (approvers?.length) {
        return {statusBadgeMessage: getPartiallyApprovedMessage(approvers, minRequiredApprovers), requestBadgeMessage: getPartiallyApprovedMessage(approvers, minRequiredApprovers), status: 'information'};
    }

    return {statusBadgeMessage: claimedBy, requestBadgeMessage: 'Claimed by ' + claimedBy, status: 'warning'};
}

const getBadgeDetailsForOpen = (approvers: string[] | undefined, minRequiredApprovers: number) : {statusBadgeMessage: string, requestBadgeMessage: string, status: BadgeStatus} => {
    if (approvers?.length) {
        return {statusBadgeMessage: getPartiallyApprovedMessage(approvers, minRequiredApprovers), requestBadgeMessage: getPartiallyApprovedMessage(approvers, minRequiredApprovers), status: 'information'};
    }

    return {statusBadgeMessage: 'Unclaimed', requestBadgeMessage: 'Unclaimed',status: 'none'};
}

const getPartiallyApprovedMessage = (approvers: string[], minRequiredApprovers: number) => {
    return `${approvers.length}/${minRequiredApprovers} Approvers`;
}