import {isInEndState} from "./QueueService";

export const getApproveButtonMessage = (data: any) => {
    if(isInEndState(data?.getIRCRequest)) {
        return 'View Approvals';
    }
    return canRevokeApprovals(data?.getIRCRequest) ? 'Edit Approvals' : 'Approve';
}

export const isAShortComment = (comment: string| undefined) => {
    return (!comment || comment?.length < 250)
}

export const hasCurrentUserApproved = (data: any, currentApprovers: string[]) => {
    return currentApprovers?.includes(data?.currentUser) || data?.getIRCRequest?.approvedBy?.includes(data?.currentUser);
}

export const canRevokeApprovals = (ircRequest: any) => {
    return ircRequest?.ircRequestStatus && (ircRequest?.ircRequestStatus === "APPROVED" || ircRequest?.ircRequestStatus === "COMPLETED")
}