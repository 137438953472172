import React from 'react';

interface TableCellProps {
	children: any;
	className?: string;
	width?: string;
}

export const TeamsTableCell = ({
   children, width = "auto", className = "teams-table-cell-standard"
}: TableCellProps) => {
	const tableStyle = {
		width
	};

	return (
		<td className={className} style={tableStyle}>
			{children}
		</td>
	);
};
