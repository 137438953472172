import {makeVar} from "@apollo/client";
import {tabNumberToQueue} from "./Queue";

const documentPath = document?.location?.pathname;
const currentRequestIdFromURL = documentPath?.substring(documentPath?.lastIndexOf("/") + 1, documentPath?.length);

export const currentQueueVar = makeVar(2);
export const currentUserVar = makeVar('');
export const currentRequestIdVar = makeVar(currentRequestIdFromURL);

export const clearCacheOfQueue = (client: any, currentQueue: number) => {
    const queryId = client.cache.identify({__typename: 'Query', ircRequestStatus: tabNumberToQueue[currentQueue]})
    if (queryId) {
        client.cache.evict({id: queryId})
    }
}