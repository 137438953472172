import {DateTime} from 'luxon';
import moment from "moment";

export const getFormattedDate = (asOfDate: string): string => {
    const date = DateTime.fromISO(asOfDate);
    return date.isValid ? date.toFormat('dd MMM yyyy') : '--';
};

export const getFormattedDateWithTimeStamp = (asOfDate: string): string => {
    const date = DateTime.fromISO(asOfDate);
    return date.isValid ? date.toFormat('dd MMM yyyy, T') : '--';
};

export const getFormattedDateForDashboard = (asOfDate: string): string => {
    const date = DateTime.fromISO(asOfDate);
    return date.isValid ? date.toFormat('dd MMM yy') : '--';
};

export const getTimeElapsedSince = (asOfDate: string): string => {
    const date = getFormattedDateWithTimeStamp(asOfDate);
    return moment(date).fromNow();
}
