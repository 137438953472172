export const queueOptions = ['DRAFT', 'READY', 'APPROVED', 'COMPLETED'];

export type Queue = typeof queueOptions[number];

export const tabNumberToQueue: { [key in number]: Queue } = {
    1: 'DRAFT',
    2: 'READY',
    3: 'APPROVED',
    4: 'COMPLETED'
};
