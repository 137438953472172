import React from 'react';
import parse from 'html-react-parser';
import {
    additionalContextsWithDisplayNames,
    returnCommaIfNotLastValue
} from "../../../services/utils/AdditionalContextFormatter";
import {Col} from "@gs-ux-uitoolkit-react/layout";
import {HorizontalScroll} from "@gs-ux-uitoolkit-react/horizontal-scroll";
import {AdditionalContextPanelStyleSheet} from "./AdditionalContextPanelStyleSheet";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";

interface AdditionalContextPanelProps {
    additionalContexts: any;
    requestDetailsResponse: any;
    showKey: boolean;
}

export const ScrollableContext = ({
                                      additionalContexts,
                                      requestDetailsResponse,
                                      showKey
                                  }: AdditionalContextPanelProps) => {
    const securityMetadata = requestDetailsResponse?.securityMetadata?.securityMetadata;
    const displayNameAdditionalContexts: Array<any> = additionalContextsWithDisplayNames(additionalContexts, securityMetadata);
    const additionalContextsStyling = useStyleSheet(AdditionalContextPanelStyleSheet, null);

    return (
        <>
            {
                displayNameAdditionalContexts?.map(({context, displayNames}, contextIndex) => (
                    <Col style={{paddingLeft: '0px'}} key={contextIndex}>
                        {displayNames?.map((displayName: string, index: number) => (
                            <strong style={{color: 'var(--gs-uitk-color-scheme-primary)'}}>
                                {displayName}
                                {returnCommaIfNotLastValue(index, displayNames.length)}
                            </strong>
                        ))}
                        {context && Object.entries(context)?.map(([key, value], index) => (
                            <Col style={{paddingLeft: '0%'}} key={index}>
                                <strong>{showKey ? key + ':' : null}</strong>
                                {
                                    React.Children.toArray(parse(value as string)).map((child, childIndex) => (
                                            React.isValidElement(child) && child.type != 'p' ? (
                                                <HorizontalScroll  style={{paddingLeft: '1%'}} key={childIndex}>
                                                    <Col className={additionalContextsStyling.contexts} style={{paddingLeft: '0%'}}>{child}</Col>
                                                </HorizontalScroll>
                                            ) : (
                                                <Col className={additionalContextsStyling.contexts} style={{paddingLeft: '1%'}} key={childIndex}>{child}</Col>
                                            )
                                        )
                                    )}
                            </Col>
                        ))}
                    </Col>
                ))
            }
        </>
    )
};
