import React from "react";

interface TableProps {
	width?: string;
	className?: string;
	children: any;
}

export const TeamsTable = ({ width = 'auto', className = "", children }: TableProps) => {
	return (
		<table style={{ backgroundColor: 'inherit', width }} className={className}>
			{children}
		</table>
	);
}
