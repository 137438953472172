export const Regions = {
    AMERICAS: 'AMERICAS',
    EMEA: 'EMEA',
    JAPAN: 'JAPAN',
    ANZ: 'ANZ',
    AEJ: 'AEJ'
} as const;

export const RegionFormattingMap: { [key: string]: string } = {
    [Regions.AMERICAS]: 'AMERICAS',
    [Regions.EMEA]: 'EMEA',
    [Regions.JAPAN]: 'APAC',
    [Regions.ANZ]: 'APAC',
    [Regions.AEJ]: 'APAC'
};