import React from 'react';
import {TeamsTableCell} from "../Table/TeamsTableCell";
import {CONVICTION_LIST_VALUE} from "../../constants/ConvictionList";

interface ConvictionListRowProps {
    securityData: any;
    styleClasses: any;
    clBadge: any;
}

export const ConvictionListRow = ({ securityData, styleClasses, clBadge }: ConvictionListRowProps) => {
    const { ticker, involvedChanges } = securityData;
    const changeValues = involvedChanges.find((involvedChange: any) => involvedChange.keyword == CONVICTION_LIST_VALUE);

    const wipValue = changeValues?.wipValue ?? "";
    const liveValue = changeValues?.liveValue ?? "";

    const securityHasLiveCLValue = () => (liveValue && liveValue.length !== 0);
    const securityIsAddedToCL = () => (!securityHasLiveCLValue() && wipValue);
    const getWipValueStyle = () => {
        if (securityIsAddedToCL())  {
            return styleClasses.tableCellWIPColoredText;
        }

        return styleClasses.tableCell;
    };

    return (
        <tr className={styleClasses.zebraStripedRow}>
            <TeamsTableCell className={styleClasses.tableCell}>{securityData.issuerName} ({ticker})
                {securityHasLiveCLValue() ? clBadge : undefined}
            </TeamsTableCell>
            <TeamsTableCell className={getWipValueStyle()}>{wipValue}</TeamsTableCell>
            <TeamsTableCell className={styleClasses.tableCell}>{liveValue}</TeamsTableCell>
        </tr>
    )

}