import {DataChange} from "./DataChange";
import React from "react";
import {Col} from "@gs-ux-uitoolkit-react/layout";
import {Text} from "@gs-ux-uitoolkit-react/text";
import {getDataChangeCount} from "../../../services/utils/DataChangeService";
import {AccordionPanel} from "@gs-ux-uitoolkit-react/accordion";

interface DataChangePanelProps {
    response: any;
    changeTypes: string[];
    onCollapse: any;
    onExpand: any;
}

export const DataChangePanel = ({
                                    onCollapse,
                                    onExpand,
                                    response,
                                    changeTypes
                                }: DataChangePanelProps) => {
    const DATA_CHANGES = 'Data Changes';
    const isNotRatedLegalChange = changeTypes?.includes('NOT_RATED_LEGAL_CHANGE');

    const panel = response?.map((securityData: any, index: number) => {
        if (getDataChangeCount([securityData]) !== 0) {
            return <DataChange
                key={index}
                data={securityData}
                isNotRatedLegalChange={isNotRatedLegalChange}
            />
        }
    });

    if (getDataChangeCount(response) === 0) {
        return null;
    }
    return (
        <AccordionPanel
            header={`${DATA_CHANGES} (${getDataChangeCount(response)})`}
            onCollapse={() => onCollapse(DATA_CHANGES)}
            onExpand={() => onExpand(DATA_CHANGES)}
        >
            <Col xs={12} sm={12} md={6} lg={4} xl={4} style={{padding: 0, margin: 0}}>
                {panel}
            </Col>
        </AccordionPanel>
    );
};
