const IRC_WORKFLOW_SERVICE_URL = 'IRC_WORKFLOW_SERVICE_URL';
const GONE_FISHING = 'GONE_FISHING';
const GONE_FISHING_START_DATE = 'GONE_FISHING_START';
const GONE_FISHING_END_DATE = 'GONE_FISHING_END';
const DATADOG_RUM_ENV = 'DATADOG_RUM_ENV';
const USE_ATTACHMENT_DEEP_LINK = 'USE_ATTACHMENT_DEEP_LINK';

export {
    IRC_WORKFLOW_SERVICE_URL,
    GONE_FISHING,
    GONE_FISHING_START_DATE,
    GONE_FISHING_END_DATE,
    DATADOG_RUM_ENV,
    USE_ATTACHMENT_DEEP_LINK
};
