import {
    noFormatting,
    stringArrayFormatter
} from '../../../services/utils/RequestDetailsFormatters';
import {getFormattedDateWithTimeStamp} from '../../../services/utils/DateService';
import {getDisplayName, getListOfDisplayNames} from "../../../services/utils/DisplayNameService";

interface DetailDefinition {
    displayName: string,
    fieldName: string,
    valueGetter: (response: any) => any;
    formatter: (value: any) => string
}
export const approvalDetailsPanelDefinition: DetailDefinition[] = [
    {
        displayName: 'Approved By',
        fieldName: 'approvedBy',
        valueGetter: (response: any) => (getListOfDisplayNames(response?.approvedBy, response?.peopleMetadata)),
        formatter: stringArrayFormatter
    },
    {
        displayName: 'Approval Date',
        fieldName: 'approvalDate',
        valueGetter: (response: any) => (response?.approvalDate),
        formatter: getFormattedDateWithTimeStamp
    },
    {
        displayName: 'Comment',
        fieldName: 'comment',
        valueGetter: (response: any) => (response?.approvalComment?.comment),
        formatter: noFormatting
    },
    {
        displayName: 'Comment Author',
        fieldName: 'author',
        valueGetter: (response: any) => (getDisplayName(response?.approvalComment?.author, response?.peopleMetadata)),
        formatter: noFormatting
    },
    {
        displayName: 'Comment Last Updated',
        fieldName: 'lastUpdated',
        valueGetter: (response: any) => (response?.approvalComment?.lastUpdated),
        formatter: getFormattedDateWithTimeStamp
    }
]
