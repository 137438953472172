import React from 'react';
import {ChatButton} from "../../Chat/ChatButton";
import {Col, Row} from "@gs-ux-uitoolkit-react/layout";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {requestFooterStyleSheet} from "../../../constants/StyleSheets";

export const RequestFooter = () => {
    const styleClasses = useStyleSheet(requestFooterStyleSheet, null);

    return (
        <div className={styleClasses.footer}>
            <Row>
                <Col xs={12} sm={6} className={styleClasses.buttonContainer} id="irc-chat-button">
                    <ChatButton chatType={'IRC_MEMBERS_ONLY'}/>
                </Col>
                <Col xs={12} sm={6} className={styleClasses.buttonContainer} id="collaborators-chat-button">
                    <ChatButton chatType={'IRC_MEMBERS_AND_REQUEST_COLLABORATORS'}/>
                </Col>
            </Row>
        </div>
    );
}
