import React from 'react';
import {
    getAdditionalContextWithoutOtherChanges,
} from "../../../services/utils/AdditionalContextFormatter";
import {AccordionPanel} from "@gs-ux-uitoolkit-react/accordion";
import {ScrollableContext} from "./ScrollableContext";

interface AdditionalContextPanelProps {
    additionalContextsResponse: any;
    requestDetailsResponse: any;
    onCollapse: any;
    onExpand: any;
}

export const AdditionalContextPanel = ({
                                           additionalContextsResponse,
                                           requestDetailsResponse,
                                           onCollapse, onExpand
                                       }: AdditionalContextPanelProps) => {

    const ADDITIONAL_CONTEXT = 'Additional Context';
    const additionalContexts: Array<any> = getAdditionalContextWithoutOtherChanges(additionalContextsResponse?.additionalContexts);

    if (!additionalContexts || additionalContexts.length == 0) {
        return null;
    }

    return (
        <AccordionPanel
            header={ADDITIONAL_CONTEXT}
            onCollapse={() => onCollapse(ADDITIONAL_CONTEXT)}
            onExpand={() => onExpand(ADDITIONAL_CONTEXT)}
        >
            <ScrollableContext additionalContexts={additionalContexts} requestDetailsResponse={requestDetailsResponse} showKey={true}/>
        </AccordionPanel>
        );
};
