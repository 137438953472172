export type Rating =
    | 'S'
    | 'B'
    | 'N'
    | 'NR'
    | 'RS'
    | 'CS'
    | 'NA';

export type RatingDisplayName =
    | 'Sell'
    | 'Buy'
    | 'Neutral'
    | 'Not Rated'
    | 'Rating Suspended'
    | 'Coverage Suspended'
    | 'Not Available';


export const ratingToRatingDisplayName: {[key in Rating]: RatingDisplayName} = {
    'S': 'Sell',
    'B': 'Buy',
    'N': 'Neutral',
    'NR': 'Not Rated',
    'RS': 'Rating Suspended',
    'CS': 'Coverage Suspended',
    'NA': 'Not Available'
};

export const LEGALLY_REQUIRED_NOT_RATED = '*Going Not Rated as a result of a legally required restriction (Takeover Panel, China SAC blackout, or Korea regulations blackout)'