import React from 'react';

interface TableHeaderCellProps {
	children: any;
	className?: string;
	width?: string;
}

export const TeamsTableHeaderCell = ({
	 children, width = "auto", className = "teams-table-cell-standard"
}: TableHeaderCellProps) => {
	const tableStyle = {
		width
	};

	return (
		<th className={className} style={tableStyle}>
			{children}
		</th>
	);
};
