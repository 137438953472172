export const isAllowedToClaim = (permissionsData: any) => {
    return permissionsData?.data?.getUserActionList?.allowedAccess?.CLAIM;
};

export const isAllowedToUpdate = (permissionsData: any) => {
    return permissionsData?.data?.getUserActionList?.allowedAccess?.UPDATE;
};

export const isAllowedToApprove = (permissionsData: any) => {
    return permissionsData?.data?.getUserActionList?.allowedAccess?.APPROVE;
};

export const isAllowedToArchive = (permissionsData: any) => {
    return permissionsData?.data?.getUserActionList?.allowedAccess?.ARCHIVE;
};