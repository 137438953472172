import React from 'react';
import {LoadingIcon} from '@gs-ux-uitoolkit-react/loading';

interface LoadingProps {
    loadingTxt: string;
}

export const Loading = ({loadingTxt}: LoadingProps) => {
	return (
		<div style={{position: 'relative'}}>
			<LoadingIcon label={loadingTxt}/>
		</div>
	);
};
