import {Button} from "@gs-ux-uitoolkit-react/button";
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import React from "react";

interface Props {
    onClick: () => void,
    config?: any
}

export const CancelButton = ({onClick, config} : Props) => {
    return (
        <>
            <Button
                id={'cancel-button'}
                actionType='destructive'
                emphasis='bold'
                name={'cancel-button'}
                onClick={onClick}
                className={config}
            >
                <Icon name='cancel' type='filled'/>
            </Button>
        </>
    )
};

export const EditButton = ({onClick, config} : Props) => {
    return (
        <>
            <Button
                id={'edit-button'}
                onClick={onClick}
                actionType={'primary'}
                className={config}
            >
                <Icon name='edit' type='filled'/>
            </Button>
        </>
    )
};
