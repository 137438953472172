import {local, devInt, devExt, qa, prod} from '../../src/constants';

interface apiType {
    [key: string]: any
}

const getConfig = () => {
    const envName = getEnvName();

    switch (envName) {
        case 'LOCAL':
            return local;
        case 'DEV_INT':
            return devInt;
        case 'DEV_EXT':
            return devExt;
        case 'QA':
            return qa;
        default:
            return prod;
    }
};

export const getEnvName = () => {
    const hostname = window?.location?.hostname;

    const hostnameMatches = (regexes: RegExp[]) => regexes.some((rg) => rg.test(hostname));

    if (hostnameMatches([
        (/localhost/),
        (/\.desktop\.services\.gs\.com/),
        (/10\.246\.82\.252/),
        (/dcnds(.*)\.firmwide\.corp\.gs\.com/),
    ])) {
        return 'LOCAL';
    }

    if (hostnameMatches([
        (/qa-/)
    ])) {
        return 'QA';
    }

    if (hostnameMatches([(/225556-(.*)-int\./)])) {
        return 'DEV_INT';
    }

    if (hostnameMatches([
        (/dev-/), (/d185al61m9med/)
    ])) {
        return 'DEV_EXT';
    }

    return 'PROD';
};

export const getEnvVariable = (variable: string) => {
    const config: apiType = getConfig();
    return config[variable];
};