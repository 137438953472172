import {Modal, ModalBody, ModalFooter, ModalHeader,} from '@gs-ux-uitoolkit-react/modal';
import {Dispatch, SetStateAction} from "react";
import {Button} from "@gs-ux-uitoolkit-react/button";
import {ArchiveButton} from "./UpdateStatusButton";

interface ArchiveModalProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

export const ArchiveModal = ({visible, setVisible}: ArchiveModalProps) => {
    const toggleVisibility = () => setVisible(!visible);

    return (
        <>
            <Modal visible={visible} onVisibilityToggle={toggleVisibility}>
                <ModalHeader onDismissButtonClick={toggleVisibility}>{"Archive Request"}</ModalHeader>
                <ModalBody>
                    <p>
                        WARNING: This action cannot be undone. By clicking "Confirm" you will archive this request permanently.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        actionType="secondary"
                        onClick={toggleVisibility}
                    >
                        Cancel
                    </Button>
                    <ArchiveButton additionalAction={() => toggleVisibility()}/>
                </ModalFooter>
            </Modal>
        </>

    )
}