import React, {useEffect, useState} from 'react';
import {variables} from "./constants";
import {getEnvVariable} from "./services/EnvironmentService";
import {BrowserRouter} from "react-router-dom";
import {GoneFishingBanner} from "./components/GoneFishing/GoneFishingBanner";
import {Concord} from "./Concord";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {getHeadersForRequest} from "./services/AuthenticatedQueryHelper";
import {RetryLink} from "@apollo/client/link/retry";
import {currentRequestIdVar, currentUserVar} from "./constants/cache";
import {lightTheme, Theme, ThemeContainer} from "@gs-ux-uitoolkit-react/theme";
import {getTheme} from "./services/ThemeService";
import {useTeams} from "msteams-react-base-component";
import {app} from "@microsoft/teams-js";

type AppContextType = {
    theme: Theme;
    setTheme: (theme: Theme) => void;
};

const defaultAppContext: AppContextType = {
    theme: lightTheme,
    setTheme: (_theme: Theme) => {
    }, // No-op
};

export const App = () => {
    const goneFishing = getEnvVariable(variables.GONE_FISHING);
    const goneFishingStartDate = getEnvVariable(variables.GONE_FISHING_START_DATE);
    const goneFishingEndDate = getEnvVariable(variables.GONE_FISHING_END_DATE);

    const [shouldAuthenticate, setShouldAuthenticate] = useState(true)
    const [theme, setTheme] = useState<Theme>(defaultAppContext.theme);
    const updateTheme = (teamsThemeName: string | undefined) => setTheme(getTheme(teamsThemeName));

    let currentDate = new Date();
    if (goneFishing || (currentDate > goneFishingStartDate && currentDate < goneFishingEndDate)) {
        return (<GoneFishingBanner beginningDateRange={goneFishingStartDate} endDateRange={goneFishingEndDate}/>);
    }

    app.initialize();

    const [{inTeams, themeString}] = useTeams({ setThemeHandler: updateTheme });

    useEffect(() => {
        if (inTeams !== undefined) {
            setShouldAuthenticate(true);
        }
    }, [inTeams]);

    useEffect(() => {
        if (themeString) {
            updateTheme(themeString);
        }
    }, [themeString]);

    const httpLink = createHttpLink({
        uri: `${getEnvVariable(variables.IRC_WORKFLOW_SERVICE_URL)}/graphql`
    });

    const authLink = setContext((_, {headers}) => {
        return getHeadersForRequest(shouldAuthenticate, headers)
    });

    const retryLink = new RetryLink();

    const client = new ApolloClient({
        link: authLink.concat(httpLink).concat(retryLink),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        currentUser: {
                            read() {
                                return currentUserVar();
                            }
                        },
                        currentRequestId: {
                            read() {
                                return currentRequestIdVar();
                            }
                        }
                    }
                }
            },
            addTypename: false
        })
    });

    return (
        <ThemeContainer theme={theme} generateCssVars>
            <BrowserRouter>
                <ApolloProvider client={client}>
                    <Concord/>
                </ApolloProvider>
            </BrowserRouter>
        </ThemeContainer>
    );
};
