import {GET_ADDITIONAL_CONTEXT, GET_REQUEST_DETAILS} from "../../../constants/GraphqlCallDefinitions";
import {useQuery} from "@apollo/client";
import {NonQuantumChangesPanel} from "../../Request/DataChanges/NonQuantumChangesPanel";
import React from "react";

export const NonQuantumChangesSummary = () => {
    const { data: additionalContextData } = useQuery(GET_ADDITIONAL_CONTEXT);
    const requestDetailsQuery = useQuery(GET_REQUEST_DETAILS);

    return (
        <NonQuantumChangesPanel additionalContextsResponse={additionalContextData?.getAdditionalContext}
                                onCollapse={undefined}
                                onExpand={undefined}
                                defaultExpanded={true}
                                requestDetailsResponse={requestDetailsQuery?.data?.getIRCRequest}/>
    );
};
