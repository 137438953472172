import {StyleSheet} from "@gs-ux-uitoolkit-react/style";

export const AdditionalContextPanelStyleSheet = new StyleSheet('additional-contexts', {
    contexts: {
        'ul': {
            listStyleType: "revert",
        },
        'ol': {
            listStyleType: "revert",
        }
    }
});