import React from 'react';
import {Badge} from '@gs-ux-uitoolkit-react/badge';
import {getStatusBadgeFormattingDetails} from "../../services/utils/RequestDetailsFormatters";
import {useQuery} from "@apollo/client";
import {GET_APPROVAL_REQUIREMENTS} from "../../constants/GraphqlCallDefinitions";

interface RequestDetailsBadgeProps {
    approvedBy: string[];
    status: string;
    claimedBy: string;
}

export const RequestDetailsBadge = ({approvedBy, status, claimedBy}: RequestDetailsBadgeProps) => {
    const minRequiredApprovers = useQuery(GET_APPROVAL_REQUIREMENTS).data?.getApprovalRequirements?.minimumNumberApprovers;

    const badgeProps = getStatusBadgeFormattingDetails(status, claimedBy, approvedBy, minRequiredApprovers);
    return (
        <Badge status={badgeProps.status} emphasis='subtle' size="lg">
            {badgeProps.requestBadgeMessage}
        </Badge>
    )
}
