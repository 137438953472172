import React, {useEffect, useState} from 'react';
import {Accordion} from '@gs-ux-uitoolkit-react/accordion';
import {RequestHeader} from './RequestHeader/RequestHeader';
import {DetailsPanel} from './RequestDetails/DetailsPanel';
import {useQuery} from '@apollo/client';
import {RequestFooter} from './RequestFooter/RequestFooter';
import {GET_REQUEST_DETAILS, GET_WIP_DATA, GET_ADDITIONAL_CONTEXT} from '../../constants/GraphqlCallDefinitions';
import {AdditionalContextPanel} from './AdditionalContext/AdditionalContextPanel';
import {DataChangePanel} from './DataChanges/DataChangePanel';
import {AttachmentsPanel} from "./AttachmentsPanel/AttachmentsPanel";
import {reportToEUM} from "../../services/EUMService";
import {ApprovalDetailsPanel} from "./RequestDetails/ApprovalDetailsPanel";
import {ConvictionListChangesPanel} from './ConvictionListChanges/ConvictionListChangesPanel';
import {NonQuantumChangesPanel} from "./DataChanges/NonQuantumChangesPanel";

export const Request = () => {
    const pollInterval = 5_000
    const [requestHasApprovals, setRequestHasApprovals] = useState(false);
    const requestDetailsQuery = useQuery(GET_REQUEST_DETAILS);
    const additionalContext = useQuery(GET_ADDITIONAL_CONTEXT);
    const {data} = useQuery(GET_WIP_DATA, {
            pollInterval
        }
    );

    const onCollapse = (panelName: string) => onTogglePanel(panelName, 'collapse');
    const onExpand = (panelName: string) => onTogglePanel(panelName, 'expand');

    const onTogglePanel = (panelName: string, actionName: string) => {
        const requestId = requestDetailsQuery?.data?.currentRequestId;
        reportToEUM(`Clicked to ${actionName} panel ${panelName} for request ${requestId}`, {requestId});
    };

    useEffect(() => {
        additionalContext.refetch();
    }, [requestDetailsQuery?.data?.getIRCRequest?.lastUpdated]);

    useEffect(() => {
        if (requestDetailsQuery.data?.getIRCRequest?.approvedBy?.length > 0) {
            setRequestHasApprovals(true);
        } else {
            setRequestHasApprovals(false);
        }
    }, [requestDetailsQuery.data?.getIRCRequest?.approvedBy]);


    return (
        <>
            <div style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: "inherit" }}>
                <RequestHeader/>
            </div>
            <div>
                <Accordion emphasis='bold'>
                    <DetailsPanel response={requestDetailsQuery?.data?.getIRCRequest}
                                  onCollapse={onCollapse}
                                  onExpand={onExpand}/>
                    <ApprovalDetailsPanel requestHasApprovals={requestHasApprovals}
                                          response={requestDetailsQuery?.data?.getIRCRequest}
                                          onCollapse={onCollapse}
                                          onExpand={onExpand}/>
                    <DataChangePanel response={data?.getRequestQuantumData}
                                     changeTypes={requestDetailsQuery?.data?.getIRCRequest?.ircSubmission?.ircChangeTypes}
                                     onCollapse={onCollapse}
                                     onExpand={onExpand}/>
                    <NonQuantumChangesPanel additionalContextsResponse={additionalContext?.data?.getAdditionalContext}
                                            requestDetailsResponse={requestDetailsQuery?.data?.getIRCRequest}
                                            defaultExpanded={false}
                                            onCollapse={onCollapse}
                                            onExpand={onExpand}/>
                    <ConvictionListChangesPanel response={data?.getRequestQuantumData}
                                                onCollapse={onCollapse}
                                                onExpand={onExpand}/>
                    <AdditionalContextPanel additionalContextsResponse={additionalContext?.data?.getAdditionalContext}
                                            requestDetailsResponse={requestDetailsQuery?.data?.getIRCRequest}
                                            onCollapse={onCollapse}
                                            onExpand={onExpand}/>
                    <AttachmentsPanel requestDetails={requestDetailsQuery?.data?.getIRCRequest}
                                      onCollapse={onCollapse}
                                      onExpand={onExpand}/>
                </Accordion>
                <RequestFooter/>
            </div>
        </>
    );
};
