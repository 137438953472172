import {app} from "@microsoft/teams-js";
import {reportToEUM} from "../EUMService";

export const getCurrentUserFromMicrosoftContext = (context: any) => {
    const principalName = context.user?.userPrincipalName;
    return principalName ? principalName.substring(0, principalName.indexOf('@')) : '';
}

export const openChat = async (chatId: String) => {
    reportToEUM(`Opening chat with chatId ${chatId}`);
    await app.openLink(`https://teams.microsoft.com/l/message/${chatId}/0?context=%7B%22contextType%22:%22chat%22%7D`);
}