import React, {ChangeEvent} from 'react';
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {approvalCommentStyleSheet} from "../../../constants/StyleSheets";
import {Col} from "@gs-ux-uitoolkit-react/layout";
import {CancelButton} from "./ApprovalCommentButtons";
import {isTouchDevice} from "@gs-ux-uitoolkit-react/shared";
import {isAShortComment} from "../../../services/utils/ApproveService";

interface Props {
    comment: string | undefined,
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
    onClick: () => void,
    isDisabled?: boolean
}

export const EditableView = ({comment, onChange, onClick, isDisabled}: Props) => {
    const cssClasses = useStyleSheet(approvalCommentStyleSheet, null);

    return (
        <>
            <Col xs={10}>
                <textarea
                    onChange={onChange}
                    rows={isAShortComment(comment) ? 4 : 8}
                    cols={isTouchDevice()? 50 : 65}
                    maxLength={500}
                    className={isTouchDevice()? cssClasses.mobileCommentBox: cssClasses.commentBox}
                    value={comment}
                    placeholder='Type your comment...'
                />
            </Col>
            <Col xs={2}>
                {!isDisabled && <CancelButton onClick={onClick}
	                                         config={isAShortComment(comment) ? cssClasses.editActionButtonShortComment : cssClasses.editActionButton}/>
                }
            </Col>
        </>
    )
};
