import axios from "axios";
import {getHeadersForRequest} from "./AuthenticatedQueryHelper";
import {getEnvVariable} from "./EnvironmentService";
import {variables} from "../constants";
import {isTouchDevice} from "@gs-ux-uitoolkit-react/shared";

export interface AttachmentInfo {
	fileUrl: string;
	// the deep link is what we should ideally be using to open the file. however in current version of Teams that we
	// have as of April 1, 2024, this functionality is broken. we have reported to Microsoft and found this active
	// bug ticket - https://github.com/OfficeDev/microsoft-teams-library-js/issues/2051
	teamsDeepLink: string;
}

export const shouldUseDeepLinkFeature = () => {
	return getEnvVariable(variables.USE_ATTACHMENT_DEEP_LINK) === true && isTouchDevice();
}

export const getAttachmentCount = (data: any) => {
	const attachments = data?.getIRCRequest?.attachments?.files;
	return attachments ? attachments.length : 0;
};

export const getAttachmentInfo = async (fileLocation: string): Promise<AttachmentInfo> => {
	const url = getAttachmentApiUrl(fileLocation);

	let headers = await getHeadersForRequest(true, {});

	return axios
		.get(url.toString(), {
			... headers,
			// for some reason the content type header is just ignored,
			// adding this empty data forces axios to send the content type header
			data: {}
		})
		.then((response) => response.data)
		.catch((error) => {
			console.log('Unable to fetch attachment', error);
			throw new Error('Unable to fetch attachment');
		});
};

const getAttachmentApiUrl = (fileLocation: string): string => {
	const url = new URL(`${getEnvVariable(variables.IRC_WORKFLOW_SERVICE_URL)}/attachments`);

	url.searchParams.append('attachmentLocation', fileLocation);

	return url.toString();
};
