import React from 'react';
import {Badge} from '@gs-ux-uitoolkit-react/badge';
import {getRegionDisplayName} from "../../services/utils/RequestDetailsFormatters";

interface RegionBadgeProps {
    region: string;
}

export const RegionBadge = ({region}: RegionBadgeProps) => {
    return (
        <Badge color="gray" shape="rounded" emphasis='minimal'>{getRegionDisplayName(region)}</Badge>
    );
}