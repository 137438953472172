import React, {useEffect, useState} from 'react';
import {getFormattedDateWithTimeStamp} from "../../../services/utils/DateService";
import {InputGroup} from "@gs-ux-uitoolkit-react/input";
import {ReadOnlyView} from "./ReadOnlyView";
import {FormFeedback, FormGroup, FormText} from "@gs-ux-uitoolkit-react/form";
import {EditableView} from "./EditableView";
import {typographyVariants} from "@gs-ux-uitoolkit-react/design-system";
import {Text} from '@gs-ux-uitoolkit-react/text';
import {GET_REQUEST_DETAILS_COMMENT} from "../../../constants/GraphqlCallDefinitions";
import {useQuery} from "@apollo/client";
import {getDisplayName} from "../../../services/utils/DisplayNameService";

interface ApprovalComment {
    comment: string,
    author: string,
    lastUpdated?: string
}

interface ApprovalCommentProps {
    updateVisibleComment: (event: string) => void,
    isDisabled: boolean
}

export const ApprovalComment = ({updateVisibleComment, isDisabled}: ApprovalCommentProps) => {
    const {data} = useQuery(GET_REQUEST_DETAILS_COMMENT, {pollInterval: 1_000});
    const oldApprovalComment: ApprovalComment = data?.getIRCRequest?.approvalComment;

    const [isReadOnly, setIsReadOnly] = useState(true);
    const [isShowingWarningToast, setIsShowingWarningToast] = useState(false);
    const [newComment, setNewComment] = useState<string>();
    const [userLastSeenComment, setUserLastSeenComment] = useState<string>(oldApprovalComment?.comment);

    const lastSavedText = (oldApprovalComment?.author) ?
        `${getDisplayName(oldApprovalComment?.author, data?.getIRCRequest?.peopleMetadata)} last saved the comment on 
        ${getFormattedDateWithTimeStamp(oldApprovalComment?.lastUpdated ? oldApprovalComment?.lastUpdated : '')}` : '';

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewComment(event.target.value);
        updateVisibleComment(event.target.value);
    }

    const someoneElseUpdatedApprovalComment = () => {
        return (userLastSeenComment != oldApprovalComment?.comment && !isReadOnly &&
            data?.currentUser != oldApprovalComment?.author)
    }

    useEffect(() => {
        if (someoneElseUpdatedApprovalComment()) {
            setIsShowingWarningToast(true)
        }
        setUserLastSeenComment(oldApprovalComment?.comment);
    }, [oldApprovalComment]);

    const toggleEdit = () => {
        setUserLastSeenComment(oldApprovalComment?.comment)
        setIsShowingWarningToast(false)
        setIsReadOnly(!isReadOnly)
        setNewComment(userLastSeenComment);
        updateVisibleComment(userLastSeenComment ? userLastSeenComment : '');
    }

    const getWarningMessage = () => (`Another user has updated the comment since you started your edits. To view their edits, press 'X'`);

    if (isReadOnly) {
        return (
            <>
                <Text style={{marginLeft: '15px', ...typographyVariants.heading06}}>Comment:</Text>
                <InputGroup>
                    <ReadOnlyView comment={userLastSeenComment} onClick={toggleEdit} isDisabled={isDisabled}/>
                </InputGroup>
                <FormText style={{marginLeft: '10px'}}>{oldApprovalComment ? lastSavedText : ''}</FormText>
            </>
        );
    }

    return (
        <>
            <Text style={{marginLeft: '15px', ...typographyVariants.heading06}}>Comment:</Text>
            <FormGroup>
                <InputGroup>
                    <EditableView comment={newComment} onChange={onChange} onClick={toggleEdit}
                                  isDisabled={isDisabled}/>
                </InputGroup>
                <FormFeedback style={{paddingLeft: '1em'}}
                              status='error'>{isShowingWarningToast ? getWarningMessage() : ''}</FormFeedback>
            </FormGroup>
        </>
    )
};
