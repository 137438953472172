import React from "react";
import {Col} from "@gs-ux-uitoolkit-react/layout";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {approvalCommentStyleSheet} from "../../../constants/StyleSheets";
import {EditButton} from "./ApprovalCommentButtons";
import {isTouchDevice} from "@gs-ux-uitoolkit-react/shared";
import {isAShortComment} from "../../../services/utils/ApproveService";

interface Props {
    comment: string | undefined,
    onClick: () => void,
    isDisabled?: boolean
}

export const ReadOnlyView = ({comment, onClick, isDisabled}: Props) => {
    const cssClasses = useStyleSheet(approvalCommentStyleSheet, null);

    return (
        <>
            <Col xs={10}>
            <textarea
                rows={isAShortComment(comment) ? 4 : 8}
                cols={isTouchDevice()? 50 : 65}
                readOnly
                disabled
                value={comment}
                placeholder={'Click button to add comment'}
                className={isTouchDevice()? cssClasses.mobileCommentBox : cssClasses.readOnlyCommentBox}/>
            </Col>
            <Col xs={2}>
                {!isDisabled && <EditButton onClick={onClick}
	                                       config={isAShortComment(comment) ? cssClasses.editActionButtonShortComment : cssClasses.editActionButton}/>
                }
            </Col>
        </>
    )
};
