export type ChatTypes =
    | 'IRC_MEMBERS_ONLY'
    | 'IRC_MEMBERS_AND_REQUEST_COLLABORATORS';

export type ChatButtonNames = 'IRC' | 'Analyst';

export type ChatIdFields = 'ircChatId' | 'analystChatId';

export const chatTypesToButtonNames: {[key in ChatTypes]: ChatButtonNames} = {
    'IRC_MEMBERS_ONLY': 'IRC',
    'IRC_MEMBERS_AND_REQUEST_COLLABORATORS': 'Analyst'
};

export const chatTypesToIdFields: {[key in ChatTypes]: ChatIdFields} = {
    'IRC_MEMBERS_ONLY': 'ircChatId',
    'IRC_MEMBERS_AND_REQUEST_COLLABORATORS': 'analystChatId'
};