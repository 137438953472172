import {Button, ReactButtonMouseEvent} from "@gs-ux-uitoolkit-react/button";
import React, {useEffect, useState} from "react";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {wizardStyleSheet} from "../../constants/StyleSheets";
import {ApproveModalStep} from "../../constants/ApproveModalSteps";
import {Checkbox} from "@gs-ux-uitoolkit-react/checkbox";
import {useNavigate} from "react-router-dom";
import {ApolloError} from "@apollo/client";

/**
 * The footer of the wizard contains the Back, Next, and Reset buttons
 */
interface WizardFooterProps {
    step: number;
    onNext: (event: ReactButtonMouseEvent) => void;
    onBack: (event: ReactButtonMouseEvent) => void;
    onApprove: () => void;
    error: ApolloError | undefined;
    approveButtonMessage: string;
    onClose: () => void;
    isDisabled: boolean;
    notRatedLegalChange: boolean;
}

export const ApprovalWizardFooter: React.FC<WizardFooterProps> = ({
                                                                      step,
                                                                      onNext,
                                                                      onBack,
                                                                      onApprove, error,
                                                                      approveButtonMessage,
                                                                      onClose,
                                                                      isDisabled,
                                                                      notRatedLegalChange,
                                                                  }: WizardFooterProps) => {
    const cssClasses = useStyleSheet(wizardStyleSheet, null);
    const [disableNextButton, setDisableNextButton] = useState<boolean>(notRatedLegalChange);
    const navigate = useNavigate();

    useEffect(() => {setDisableNextButton(notRatedLegalChange)}, [step, notRatedLegalChange]);
    function navigateToDashboardOnClose() {
        if (error) {
            return onClose();
        }
        navigate('/');
        onClose();
    }

    return (
        <div className={cssClasses.footer}>
            {step == ApproveModalStep.SummaryReviewStep.valueOf() && (
                <div>
                    <div className={cssClasses.buttonWithCheckbox}>
                        {notRatedLegalChange && <div className={cssClasses.checkbox}>
                                <Checkbox  key={step} onChange={() => setDisableNextButton(!disableNextButton)}>
                                    I confirm we are going Not Rated as a result of legally required restrictions
                                </Checkbox>
                        </div>}

                        <Button onClick={onNext} actionType="primary" disabled={disableNextButton}>
                            Next
                        </Button>
                    </div>

                </div>
            )}
            {step == ApproveModalStep.ApproverStep.valueOf() && (
                <div>
                    <Button onClick={onBack}>Back</Button>
                    <Button onClick={onNext} actionType="primary">
                        Next
                    </Button>
                </div>
            )}
            {step == ApproveModalStep.ApproverCommentStep.valueOf() && (
                <div>
                    <Button onClick={onBack}>Back</Button>
                    {
                        isDisabled
                            ? <Button onClick={onClose} actionType="primary"> Close </Button>
                            : <Button onClick={onApprove} actionType="primary"> {approveButtonMessage}</Button>
                    }
                </div>
            )}

            {step == ApproveModalStep.FinishedStep.valueOf() && (
                <div>
                    <Button onClick={navigateToDashboardOnClose}>Close</Button>
                </div>
            )}
        </div>
    );
};
