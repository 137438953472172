import React from 'react';
import {useQuery} from "@apollo/client";
import {GET_ADDITIONAL_CONTEXT, GET_WIP_DATA} from "../../../constants/GraphqlCallDefinitions";
import {DataChangeSummary} from "./DataChangeSummary";
import {ListGroup} from "@gs-ux-uitoolkit-react/list-group";
import {Text} from "@gs-ux-uitoolkit-react/text";
import {NonQuantumChangesSummary} from "./NonQuantumChangesSummary";
import {getAdditionalContextForOtherChange} from "../../../services/utils/AdditionalContextFormatter";

export const SummaryReview = () => {
    const {data} = useQuery(GET_WIP_DATA);
    const { data: additionalContextData } = useQuery(GET_ADDITIONAL_CONTEXT);
    const nonQuantumChangeList = getAdditionalContextForOtherChange(additionalContextData?.getAdditionalContext?.additionalContexts);

    if (!data?.getRequestQuantumData || data?.getRequestQuantumData?.length === 0 && nonQuantumChangeList?.length < 1) {
        return (<Text>No data changes provided</Text>);
    }

    const panel = data?.getRequestQuantumData.map((securityData: any, index: number ) => {
        const hasChangeTypes = securityData?.changeTypes?.length > 0;
        if (hasChangeTypes) {
            return <DataChangeSummary key={index} data={securityData} />
        }
    });

    return (
        <ListGroup>
            {panel}
            <NonQuantumChangesSummary/>
        </ListGroup>
    )
}
