import React from 'react';
import {Alert} from '@gs-ux-uitoolkit-react/alert';

interface GoneFishingProps {
    beginningDateRange?: Date;
    endDateRange?: Date;
}

export const GoneFishingBanner: React.FC<GoneFishingProps> = ({beginningDateRange, endDateRange}: GoneFishingProps) => {
    const language = "en-US";
    return (
        <Alert status="warning">
            <div>The Concord Application is currently unavailable due to scheduled maintenance.</div>
            {beginningDateRange !== undefined && endDateRange !== undefined && (<div>The app will be unavailable from {beginningDateRange?.toLocaleTimeString(language)}, {beginningDateRange?.toLocaleDateString(language)} to {endDateRange?.toLocaleTimeString(language)}, {endDateRange?.toLocaleDateString(language)}.</div>)}
            <div>Please contact <a href="mailto:gs-res-it-publishing@gs.com?subject=Concord Maintenance&cc=gs-res-it-mc@gs.com">gs-res-it-publishing </a> with any questions.</div>
        </Alert>
    );
};
