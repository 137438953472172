import React, {useEffect} from 'react';
import {DocumentNode, useQuery} from "@apollo/client";
import {ApolloQueryHandler} from "./ApolloQueryHandler";
import {getQueuesToCount} from "../services/utils/QueueService";
import {reportToEUM} from "../services/EUMService";
import {currentRequestIdVar} from "../constants/cache";

const pollInterval = 5_000

interface LoadDataProps {
    query: DocumentNode;
    ChildComponent: React.ReactElement;
    queue?: string;
    shouldSkip?: boolean;
}

export const LoadData = ({query, ChildComponent, queue, shouldSkip = false} : LoadDataProps) => {
    const {loading, error} = useQuery(query, {
            pollInterval,
            skip: shouldSkip,
            variables: {
                queryCriteriaValue: {ircRequestQueue: queue, queuesToCount: getQueuesToCount(queue)}
            }
        }
    );

    return (
        <ApolloQueryHandler
            errors={error}
            loading={loading}
            alertMessage={"Failed to load. If the error persists, please reach out to gs-res-it-mc for assistance."}
            ChildComponent={ChildComponent}/>
    )
}