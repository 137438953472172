import React from 'react';
import ErrorAlertNotification from "./Notification/ErrorAlertNotification";
import {Loading} from "./Loading/Loading";

interface ApolloQueryHandlerProps {
    errors: Error | undefined;
    loading: boolean
    alertMessage: string;
    ChildComponent: React.ReactElement;
}

export const ApolloQueryHandler = ({errors, loading, alertMessage, ChildComponent}: ApolloQueryHandlerProps) => {
    if (loading) return <Loading loadingTxt="Loading"/>;
    if (errors) return <ErrorAlertNotification
        alertMessage={alertMessage}
    />;

    return ChildComponent
}
