import {Button} from '@gs-ux-uitoolkit-react/button';
import {Icon} from '@gs-ux-uitoolkit-react/icon-font';
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_CHAT, GET_REQUEST_DETAILS, GET_USER_PERMISSIONS} from "../../constants/GraphqlCallDefinitions";
import {ChatTypes, chatTypesToButtonNames, chatTypesToIdFields} from "../../constants/ChatTypes";
import {ButtonStatus} from "@gs-ux-uitoolkit-common/button/dist/button/button-props";
import {useEffect, useState} from "react";
import {showToast} from "../Notification/NotificationService";
import {openChat} from "../../services/utils/TeamsService";
import {reportToEUM} from "../../services/EUMService";
import {currentRequestIdVar} from "../../constants/cache";
import {isAllowedToClaim, isAllowedToUpdate} from "../../services/utils/PermissionDataService";

interface ChatButtonProps {
    chatType: ChatTypes;
}

export const ChatButton = ({chatType}: ChatButtonProps) => {
    const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('loading');
    const [permissionDataLoading, setIsLoading] = useState<boolean>(true);
    const ircRequestData = useQuery(GET_REQUEST_DETAILS);
    const [chatId, setChatId] = useState<String | undefined | null>(undefined);
    const [createChat, {
        loading,
        error
    }] = useMutation(CREATE_CHAT);
    const permissionsData= useQuery(GET_USER_PERMISSIONS, {
        variables: {
            ircRequestIdValue: currentRequestIdVar()
        },
    });
    const isUserAllowedToUpdate = isAllowedToUpdate(permissionsData);
    const isUserAllowedToClaim = isAllowedToClaim(permissionsData);

    function disableChatButton() {
        if (chatType === 'IRC_MEMBERS_ONLY') {
            return !isUserAllowedToClaim;
        }
        return !isUserAllowedToUpdate;
    }

    useEffect(() => {
        if(isUserAllowedToUpdate !== undefined) {
            setIsLoading(false);
        }
    }, [isUserAllowedToUpdate]);

    const onClick = async () => {
        reportToEUM(`Clicked to create chat of type ${chatType} with chatId ${chatId}`, {chatType});
        if (!chatId) {
            setButtonStatus('loading');
            const createChatData = await createChat({variables: {chatType: chatType}})
            setChatId(createChatData?.data?.createChat?.chatId);
            if (!error && !loading) {
                setButtonStatus('none');
                await openChat(createChatData?.data?.createChat?.chatId);
            }
        } else {
            await openChat(chatId);
        }
    }

    useEffect(() => {
        if (ircRequestData?.data?.getIRCRequest) {
            setChatId(ircRequestData?.data?.getIRCRequest[chatTypesToIdFields[chatType]]);
        }
    }, [ircRequestData?.data?.getIRCRequest[chatTypesToIdFields[chatType]]])

    useEffect(() => {
        if(permissionDataLoading && !error) {
            setButtonStatus('loading');
        }

        if(!permissionDataLoading && !loading && !error) {
            setButtonStatus('none');
        }
        if (error) {
            setButtonStatus('error');
            showToast({
                status: 'error',
                message: `Unable to create chat. If the error continues, please reach out to Publication Support.`,
                autoDismiss: 15000,
            });
        }
    }, [loading, error, permissionDataLoading]);

    const chatName = chatTypesToButtonNames[chatType];
    const chatMessage = chatId ? `Resume ${chatName} Chat` : `Create ${chatName} Chat`;

    return (
        <Button
            onClick={onClick}
            status={buttonStatus}
            actionType="contrast"
            emphasis="bold"
            style={{marginRight: '8px'}}
            disabled={disableChatButton()}
        >
            <Icon name="chat-bubble" type="outlined"/>
            {chatMessage}
        </Button>
    )
}
