import {darkTheme, lightTheme, Theme} from "@gs-ux-uitoolkit-react/theme";

export const TEAMS_LIGHT_MODE = 'default';
export const TEAMS_DARK_MODE = 'dark';

export function getTheme(teamsThemeName: string | undefined): Theme {
    if (teamsThemeName && teamsThemeName === TEAMS_DARK_MODE) {
        return darkTheme;
    }
    return lightTheme;
}