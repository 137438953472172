import {IconName} from "@gs-ux-uitoolkit-common/icon-font/dist/design-system-icons/full-set";

const fileNameMatches = (fileName: string, regexes: RegExp[]) => regexes.some((rg) => rg.test(fileName));

export const getFileIcon = (fileName: string): IconName => {
	if (/\.pdf$/i.test(fileName)) {
		return 'file-pdf';
	}

	if (/\.doc([xm])?$/i.test(fileName)) {
		return 'file-word';
	}

	if (/\.ppt([xm])?$/i.test(fileName)) {
		return 'file-powerpoint';
	}

	if (/\.xls([xmb])?$/i.test(fileName)) {
		return 'file-excel';
	}

	if (fileNameMatches(fileName, [
			(/\.jpg$/i), (/\.jpeg$/i), (/\.png$/i), (/\.tiff$/i), (/\.gif$/i)
		])
	) {
		return 'file-image';
	}

	return 'file';
}