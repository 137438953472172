import {Button} from '@gs-ux-uitoolkit-react/button';
import {useMutation, useQuery} from "@apollo/client";
import {UPDATE_STATUS_ACTIONER, GET_USER_PERMISSIONS} from "../../constants/GraphqlCallDefinitions";
import {showToast} from "../Notification/NotificationService";
import {useEffect, useState} from "react";
import {ButtonStatus} from "@gs-ux-uitoolkit-common/button/dist/button/button-props";
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import {reportToEUM} from "../../services/EUMService";
import {currentRequestIdVar, currentUserVar} from "../../constants/cache";
import {isAllowedToClaim} from "../../services/utils/PermissionDataService";

interface StatusButtonInterfaceProps {
    actioner?: string;
    additionalAction?: () => void;
}


interface StatusButtonProps {
    fieldToUpdate: string;
    displayValue: string;
    additionalActionOnSuccess?: () => void;
    action: string;
}

export const ClaimButton = ({actioner}: StatusButtonInterfaceProps) => {
    const defaultClaimText = `Claim Request`
    const reassignClaimText = `Reassign to Me`

    return (
        <UpdateStatusButton fieldToUpdate={"claimedBy"}
                            displayValue={!actioner ? defaultClaimText : reassignClaimText}
                            action={"claim"}

        />
    )
}

export const ArchiveButton = ({additionalAction}: StatusButtonInterfaceProps) => {
    return (
        <UpdateStatusButton fieldToUpdate={"archivedBy"}
                            displayValue={"Confirm"}
                            additionalActionOnSuccess={additionalAction}
                            action={"archive"}
        />
    )
}

export const UpdateStatusButton = ({
                                       fieldToUpdate,
                                       displayValue,
                                       additionalActionOnSuccess,
                                       action
                                   }: StatusButtonProps) => {
    const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('loading');
    const [clicked, setClicked] = useState<boolean>(false);
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [updateStatus, {
        loading, error
    }] = useMutation(UPDATE_STATUS_ACTIONER, {variables: {updatedAttributes: {[fieldToUpdate]: currentUserVar()}}});

    const permissionsData= useQuery(GET_USER_PERMISSIONS, {
        variables: {
            ircRequestIdValue: currentRequestIdVar(),
        },
    });
    const isUserAllowedToClaim = isAllowedToClaim(permissionsData);

    function disableClaimButton(action: string) {
        return action != 'archive' && !isUserAllowedToClaim;
    }

    useEffect(() => {
        if(isUserAllowedToClaim !== undefined) {
            setInitialLoad(false);
        }
    }, [isUserAllowedToClaim]);

    const onClick = () => {
        setClicked(true);
        setButtonStatus('loading');
        reportToEUM(`Clicked to ${action} request ${currentRequestIdVar()}`, {requestId: currentRequestIdVar()});
        updateStatus();
    };

    useEffect(() => {
        if (error && !initialLoad) {
            setButtonStatus('error');
            showToast({
                status: 'error',
                message: `Unable to claim request. If the error continues, please reach out to Publication Support.`,
                autoDismiss: 15000,
            });
        } else if(!error && !loading && !initialLoad && !clicked) {
            setButtonStatus('none');
        } else if (buttonStatus === 'loading' && !loading && !initialLoad && clicked) {
            setButtonStatus('success');
            if (additionalActionOnSuccess !== undefined) {
                additionalActionOnSuccess();
            }
        }
    }, [loading, error, initialLoad]);

    return (
        <>
            <Button
                actionType='primary'
                onClick={onClick}
                status={buttonStatus}
                disabled={disableClaimButton(action)}
            >
                {
                    error ? <Icon name="refresh" type="filled"/> : undefined
                }
                {displayValue}
            </Button>
        </>
    )
}