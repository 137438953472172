import React, {useEffect, useState} from 'react';
import {Tab, Tabs} from '@gs-ux-uitoolkit-react/tabs';
import {LoadData} from "../LoadData";
import {QUERY_BY_QUEUE} from "../../constants/GraphqlCallDefinitions";
import {QueryGrid} from "./QueryGrid";
import {clearCacheOfQueue, currentQueueVar} from "../../constants/cache";
import {useApolloClient, useQuery} from '@apollo/client';
import {
    createTabVariables,
    getQueueCountDataGivenTab,
    getQueuesToCount,
    TabVariables
} from "../../services/utils/QueueService";
import {tabNumberToQueue} from "../../constants/Queue";
import {Badge} from "@gs-ux-uitoolkit-react/badge";
import {reportToEUM} from "../../services/EUMService";

export const Queues = () => {
    const [activeTab, setActiveTab] = React.useState(currentQueueVar);
    const [loadedQueueCounts, setLoadedQueueCounts] = useState(undefined);
    const {data, loading, error} = useQuery(QUERY_BY_QUEUE, {
        variables: {
            queryCriteriaValue: {
                ircRequestQueue: tabNumberToQueue[activeTab],
                queuesToCount: getQueuesToCount(tabNumberToQueue[activeTab])
            }
        }
    });
    const client = useApolloClient();

    useEffect(() => {
        if(!loading && !error) {
            setLoadedQueueCounts(data?.getIRCRequestsByQueue?.queueCounts);
        }
    }, [data?.getIRCRequestsByQueue?.queueCounts]);

    function onTabSelect(key: number) {
        reportToEUM(`Switched queue to ${tabNumberToQueue[key]}`);
        clearCacheOfQueue(client, key);
        setActiveTab(key);
        currentQueueVar(key);
    }

        const createTab = ({tabKey, tabName, queue}: TabVariables) => {
            const queueCount = getQueueCountDataGivenTab(loadedQueueCounts, tabKey)
            return (
                <Tab tabKey={tabKey} title={
                    <div>{tabName} {queueCount != null && queueCount != undefined &&
						<Badge emphasis="subtle" shape="rounded">
                            {queueCount}
						</Badge>
                    }
                    </div>
                }>
                    {(tabKey === activeTab) &&
						<LoadData
							query={QUERY_BY_QUEUE}
							ChildComponent={<QueryGrid queueName={queue}/>}
							queue={queue}
							shouldSkip={activeTab != tabKey}
						/>}
                </Tab>
            )
        };

    return (
        <div style={{padding: '6px 16px'}}>
            <Tabs variant="tabs" activeTabKey={activeTab} onSelect={onTabSelect} size="lg">
                {createTab(createTabVariables(1))}
                {createTab(createTabVariables(2))}
                {createTab(createTabVariables(3))}
                {createTab(createTabVariables(4))}
            </Tabs>
        </div>
    );
};
