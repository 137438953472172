import React from 'react';
import {approvalDetailsPanelDefinition} from './ApprovalDetailsPanelDefinitions';
import {Detail} from "./Detail";
import {AccordionPanel} from "@gs-ux-uitoolkit-react/accordion";

interface ApprovalDetailsPanelProps {
    requestHasApprovals: boolean;
    response: any;
    onCollapse: any;
    onExpand: any;
}

export const ApprovalDetailsPanel = ({
                                         requestHasApprovals,
                                         response,
                                         onCollapse,
                                         onExpand
                                     }: ApprovalDetailsPanelProps) => {
    const APPROVAL_DETAILS = 'Approval Details';
    const panel = approvalDetailsPanelDefinition.map((definition, index) => {
        return (
            <>
                {
                    definition.valueGetter(response)?.length > 0 &&
                    <Detail key={index} response={response} detailDefinition={definition}/>
                }
            </>
        );
    });

    if (!requestHasApprovals) {
        return null;
    }

    return (
        <AccordionPanel
            header={APPROVAL_DETAILS}
            defaultExpanded
            onCollapse={() => onCollapse(APPROVAL_DETAILS)}
            onExpand={() => onExpand(APPROVAL_DETAILS)}
        >
            <>
                {panel}
            </>
        </AccordionPanel>
    );
};
