import {Badge} from "@gs-ux-uitoolkit-react/badge";
import {TeamsTable} from "../../Table/TeamsTable";
import {TeamsTableCell} from "../../Table/TeamsTableCell";
import {TeamsTableHeaderCell} from "../../Table/TeamsTableHeaderCell";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {dataChangeTableStyleSheet, wizardStyleSheet} from "../../../constants/StyleSheets";
import {
    getIssuerAndTickerDisplayName,
    getMALiveValue,
    getMAWipValue,
    getRatingLiveValueDisplayName,
    getRatingWipValueDisplayName,
    getTargetPriceLiveValue, getTargetPricePeriodLiveValue, getTargetPricePeriodWipValue,
    getTargetPriceWipValue
} from "../../../services/utils/DataChangeService";
import {getConvictionListLiveValue} from "../../../services/utils/ConvictionListService";
import React from "react";
import {LEGALLY_REQUIRED_NOT_RATED} from "../../../constants/Rating";

interface DataChangesPanelProps {
    data: any;
    isNotRatedLegalChange: boolean;
}

export const DataChange = ({data, isNotRatedLegalChange}: DataChangesPanelProps) => {
    const styleClasses = useStyleSheet(dataChangeTableStyleSheet, null);
    const convictionListLiveValue = getConvictionListLiveValue(data);

    const isPriceTargetPeriodChange = getTargetPricePeriodLiveValue(data) != getTargetPricePeriodWipValue(data);

    const clBadge = (
        <Badge shape="rounded" className={styleClasses.convictionListBadge} status="information" emphasis="subtle">
            CL
        </Badge>
    );

    const targetPricePeriod = (
        <tr className={styleClasses.zebraStripedRow}>
            <TeamsTableCell className={styleClasses.tableCell}>Target Price Period</TeamsTableCell>
            <TeamsTableCell className={styleClasses.tableCell}>{getTargetPricePeriodWipValue(data)}</TeamsTableCell>
            <TeamsTableCell className={styleClasses.tableCell}>{getTargetPricePeriodLiveValue(data)}</TeamsTableCell>
        </tr>
    );
    return (
        <div key={data.liveValue}>
            {isNotRatedLegalChange &&
                <div className={styleClasses.banner}>
                    {LEGALLY_REQUIRED_NOT_RATED}
                </div>}
            <TeamsTable width="100%" className={styleClasses.table}>
                <thead>
                <tr>
                    <TeamsTableHeaderCell width="60%" className={styleClasses.securityNameCell}>
                        {getIssuerAndTickerDisplayName(data)}
                        {convictionListLiveValue !== undefined && convictionListLiveValue !== "" ? clBadge : undefined ? clBadge : undefined}
                    </TeamsTableHeaderCell>
                    <TeamsTableHeaderCell width="20%" className={styleClasses.headerCell}>
                        New
                    </TeamsTableHeaderCell>
                    <TeamsTableHeaderCell width="20%" className={styleClasses.headerCell}>
                        Current
                    </TeamsTableHeaderCell>
                </tr>
                </thead>
                <tbody>
                <tr className={styleClasses.zebraStripedRow}>
                    <TeamsTableCell className={styleClasses.tableCell}>Rating</TeamsTableCell>
                    <TeamsTableCell
                        className={styleClasses.tableCell}>{getRatingWipValueDisplayName(data)}</TeamsTableCell>
                    <TeamsTableCell
                        className={styleClasses.tableCell}>{getRatingLiveValueDisplayName(data)}</TeamsTableCell>
                </tr>
                <tr className={styleClasses.zebraStripedRow}>
                    <TeamsTableCell className={styleClasses.tableCell}>Price Target</TeamsTableCell>
                    <TeamsTableCell className={styleClasses.tableCell}>{getTargetPriceWipValue(data)}</TeamsTableCell>
                    <TeamsTableCell className={styleClasses.tableCell}>{getTargetPriceLiveValue(data)}</TeamsTableCell>
                </tr>
                {isPriceTargetPeriodChange && targetPricePeriod}
                <tr className={styleClasses.zebraStripedRow}>
                    <TeamsTableCell className={styleClasses.tableCell}>M&A Rank</TeamsTableCell>
                    <TeamsTableCell className={styleClasses.tableCell}>{getMAWipValue(data)}</TeamsTableCell>
                    <TeamsTableCell className={styleClasses.tableCell}>{getMALiveValue(data)}</TeamsTableCell>
                </tr>
                </tbody>
            </TeamsTable>
        </div>
    );
}
