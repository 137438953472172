import {Row} from "@gs-ux-uitoolkit-react/layout";
import {ListGroupItemText} from "@gs-ux-uitoolkit-react/list-group";
import {StatusBadge} from "../../Badges/StatusBadge";
import {TooltipTarget} from "@gs-ux-uitoolkit-react/tooltip";
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";

interface StatusColumnProps {
    approvers: string[] | undefined,
    claimedBy: string,
    ircRequestStatus: string,
    meetingRequired: boolean,
    ircQuantumChangeSetKeys: string[] | undefined
}

const blankSpaces: React.ReactNode = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

export const StatusColumn = ({
                                 approvers,
                                 claimedBy,
                                 ircRequestStatus,
                                 meetingRequired,
                                 ircQuantumChangeSetKeys} : StatusColumnProps) => {

    return (
        <>
            <Row>
                <ListGroupItemText>
                    <StatusBadge status={ircRequestStatus} claimedBy={claimedBy} approvers={approvers}/>
                </ListGroupItemText>
            </Row>
            <Row style={{paddingLeft: '0.5em', paddingTop: '0.5em'}}>
                {
                    meetingRequired &&
                    (
                        <>
                            <TooltipTarget label="Meeting Required">
                                <Icon id="meeting-icon" name="partner-exchange" type="filled"
                                      style={{justifyContent: 'center', color: 'var(--gs-uitk-color-core-dark-bold)'}}/>
                            </TooltipTarget>
                            {blankSpaces}
                        </>
                    )
                }
                {
                    (ircQuantumChangeSetKeys && ircQuantumChangeSetKeys?.length > 0) &&
                    (
                        <>
                            <TooltipTarget label="WIP Data Submitted">
                                <Icon id="chart-icon" name="chart-areaspline" type="filled"
                                      style={{color: 'var(--gs-uitk-color-core-dark-minimal)'}}/>
                            </TooltipTarget>
                        </>
                    )
                }
            </Row>
        </>
    )
}
