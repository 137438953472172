import {CONVICTION_LIST, CONVICTION_LIST_VALUE} from "../../constants/ConvictionList";
import {findArrayElementByTitle} from "./DataChangeService";

export const getConvictionListChangeCount = (getQuantumDataResponse: any) => {
    if (!getQuantumDataResponse || getQuantumDataResponse.length === 0) {
        return 0;
    }

    const convictionListChanges = getQuantumDataResponse
        .filter((quantumData: any) => quantumData?.changeTypes?.includes(CONVICTION_LIST));
    return convictionListChanges?.length;
};

export function getConvictionListLiveValue(data: any): string {
    return findArrayElementByTitle(data.involvedChanges, CONVICTION_LIST_VALUE)?.liveValue;
}

export function getConvictionListWipValue(data : any) : string {
    return findArrayElementByTitle(data.involvedChanges, CONVICTION_LIST_VALUE)?.wipValue;
}