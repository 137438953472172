import {authentication} from '@microsoft/teams-js';

// TODO: Rethink the way we handle auth locally before going to prod
export const getHeadersForRequest = async (shouldAuthenticate: boolean, existingHeaders: any) => {
    let access_token = 'undefined';
    if (shouldAuthenticate) {
        access_token = await authentication.getAuthToken();
    }

    return {
        headers: {
            ...existingHeaders,
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json;charset=utf-8'
    }}
}
