import React from 'react';
import {getFormattedFieldValueFromResponse} from '../../../services/utils/RequestDetailsFormatters';
import {TeamsTable} from "../../Table/TeamsTable";
import {TeamsTableCell} from "../../Table/TeamsTableCell";

interface DetailsPanelProps {
    response: any;
    detailDefinition: any;
}

export const Detail = ({response, detailDefinition}: DetailsPanelProps) => {
    return (
        <TeamsTable>
            <tbody>
                <tr>
                    <TeamsTableCell>
                        <strong>{detailDefinition.displayName}:{' '}</strong>
                    </TeamsTableCell>
                    <TeamsTableCell>
                        {getFormattedFieldValueFromResponse(response, detailDefinition.valueGetter, detailDefinition.formatter)}
                    </TeamsTableCell>
                </tr>
            </tbody>
        </TeamsTable>
    );
};
